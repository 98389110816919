import configurer from './Configurer';

export const LOGIN_ACTION_URL = configurer.PORTAL_LOGIN_URL;

export const B2C = {
    URL: configurer.PORTAL_LOGIN_URL
};

// Get Global API URL
export const API_URL = configurer.API_BASE_URL;

//Google map
export const GOOGLE_MAPS_DIRECTIONS_BASE_URL = `https://www.google.com/maps/dir/?api=1&destination=`;

export const INTERNAL_API_GET = '/pantamera-internal/get?endpoints=';
export const INTERNAL_API_POST = '/pantamera-internal/post?endpoints=';
export const INTERNAL_API_DELETE = '/pantamera-internal/delete?endpoints=';
export const ACTIVE_CUSTOMER_API = `/active-customer`;

const AGREEMENT_DOCUMENTS = `agreement-documents`
const ALL_CUSTOMERS = `customers`;
const ASSOCIATION_BAG_LABELS = `customers/association/bag-labels`;
const ASSOCIATION_FLYERS = `customers/association/flyers`
const ASSOCIATION_WEBSHOP = `customers/association/webshop`
const CAMPAIGNS = `campaigns`;
const COMPANY_INFORMATIONS = `customers/company-informations`;
const COMPENSATION = `compensation`
const CUSTOMER_EQUIPMENT = `customers/customer/equipment`
const CUSTOMER_IGLOOS = `customers/customer/igloos`
const CUSTOMER_PAYMENTS = `customers/customer/payments`;
const CUSTOMER_PICKUPS = `customers/customer/pickups`
const CUSTOMER_STATS = `customers/customer/statistics`;
const FORMS = `forms`;
const PROFILE = `profile`;
const RECYCLING_SITES = `recycling-sites`;
const SEARCH = `/search`;
const STORE_FLYERS = `customers/store/store-flyers`
const SUPPLIER_ARTICLE_REGISTRATION = `customers/supplier/article-registrations`;
const SUPPLIER_ARTICLES = `customers/supplier/articles`;
const CUSTOMER_BASE_DATA = `customer/basedata`;
const SUPPLIER_COMPANY_REGISTRATION = `customers/supplier/registrations`;
const SUPPLIER_DEPOSIT_LABELS = `customers/supplier/deposit-labels`;
const SUPPLIER_INVOICES = `customers/supplier/invoices`;
const SUPPLIER_SALESREPORTING = `customers/supplier/sales-reports`
const SUPPLIER_SALES_STATEMENTS = `customers/supplier/sales-statements`
const SUPPLIER_STATS = `customers/supplier/stats`;
const TOKEN = `${LOGIN_ACTION_URL}/token`;
const USER_MANAGEMENT = `customers/user-management`;

export const ENDPOINT = {
    TOKEN,
    AgreementDocuments: {
        Agreements: (id: string, type: string) =>
            `${INTERNAL_API_GET}${AGREEMENT_DOCUMENTS}/agreements/${id}/${type}`,
        DownloadAgreementPdf: (
            agreementDocumentId: string,
            appendixName: string
        ) =>
            `${INTERNAL_API_GET}${AGREEMENT_DOCUMENTS}/download-pdf/${agreementDocumentId}?appendixName=${appendixName}`,
        BeginSigning: (agreementDocumentId: string) =>
            `${INTERNAL_API_POST}${AGREEMENT_DOCUMENTS}/begin-signing/${agreementDocumentId}`,
        CollectSigning: (transactionId: string) =>
            `${INTERNAL_API_GET}${AGREEMENT_DOCUMENTS}/collect-signing/${transactionId}`,
    },
    AssociationBagLabels: {
        Widget: () => `${INTERNAL_API_GET}${ASSOCIATION_BAG_LABELS}/widget`,
        FormSetup: () => `${INTERNAL_API_GET}${ASSOCIATION_BAG_LABELS}/form-setup`,
        CreateOrder: () => `${INTERNAL_API_POST}${ASSOCIATION_BAG_LABELS}`,
    },
    AssociationFlyers: {
        Flyers: () => `${INTERNAL_API_GET}${ASSOCIATION_FLYERS}`,
        DownloadFlyer: () => `${INTERNAL_API_POST}${ASSOCIATION_FLYERS}/download`,
    },
    AssociationWebshop: {
        Orders: () => `${INTERNAL_API_GET}${ASSOCIATION_WEBSHOP}/orders`,
    },
    Campaign: {
        AssociationToplist: (year: string) =>
            `${INTERNAL_API_GET}${CAMPAIGNS}/association-rankings/${year}`,
        CheckEan: (ean: string) => `${INTERNAL_API_GET}${CUSTOMER_BASE_DATA}/check-ean/${ean}`,
    },
    CompanyRegistrations: {
        ValidateOrgNr: (type: 'supplier' | 'customer', orgNr: string) =>
            `${INTERNAL_API_GET}${CUSTOMER_BASE_DATA}/registrations/validateorganisationnr/${type}/${orgNr}`,
    },
    Compensation: {
        PexStations: () => `${INTERNAL_API_GET}${COMPENSATION}/recycling-stations`,
        SubmitCompensation: () => `${INTERNAL_API_POST}${COMPENSATION}`,
    },
    Customer: {
        DepositStats: () => `${INTERNAL_API_GET}${CUSTOMER_STATS}/deposits`,
        DownloadPickups: (start: string, end: string) =>
            `${INTERNAL_API_POST}${CUSTOMER_PICKUPS}/excel?startdate=${start}&enddate=${end}`,
        Equipment: () => `${INTERNAL_API_GET}${CUSTOMER_BASE_DATA}/equipment`,
        SetActiveCustomer: () => `${ACTIVE_CUSTOMER_API}/set`,
        GetActiveCustomer: () => `${ACTIVE_CUSTOMER_API}`,
        Pickups: (start: string, end: string) =>
            `${INTERNAL_API_GET}${CUSTOMER_PICKUPS}?startdate=${start}&enddate=${end}`,
        Stats: (start: string, end: string) =>
            `${INTERNAL_API_GET}${CUSTOMER_STATS}/widget?startdate=${start}&enddate=${end}`,
        SubmitExtraPickup: () => `${INTERNAL_API_POST}${CUSTOMER_PICKUPS}/extra`,
        SubmitExtraPickupViaForm: () => `${INTERNAL_API_POST}${CUSTOMER_PICKUPS}/extra-via-form`,
        SubmitEquipmentOrder: () => `${INTERNAL_API_POST}${CUSTOMER_EQUIPMENT}/order`,
    },
    CustomerIgloos: {
        Igloos: () => `${INTERNAL_API_GET}${CUSTOMER_IGLOOS}`,
        IgloosExtraPickup: () => `${INTERNAL_API_POST}${CUSTOMER_IGLOOS}/extra`,
    },
    CustomerPayments: {
        Payments: (
            fromDate: string,
            toDate: string,
            rvms: string,
            onlyPaid: boolean,
            invoiceNr: string,
            reference: string
        ) =>
            `${INTERNAL_API_GET}${CUSTOMER_PAYMENTS}?fromDate=${fromDate}&toDate=${toDate}&onlyPaid=${onlyPaid}&rvms=${rvms}&invoiceNr=${invoiceNr}&reference=${reference}`,
        Rvms: () => `${INTERNAL_API_GET}${CUSTOMER_PAYMENTS}/rvms`,
        MonthlyReports: () => `${INTERNAL_API_GET}${CUSTOMER_PAYMENTS}/monthly-reports`,
        MonthlyReportsAssociation: () =>
            `${INTERNAL_API_GET}${CUSTOMER_PAYMENTS}/monthly-reports-association`,
        LatestPayments: () => `${INTERNAL_API_GET}${CUSTOMER_PAYMENTS}/latest`,
        LatestPaymentsAssociation: () =>
            `${INTERNAL_API_GET}${CUSTOMER_PAYMENTS}/latest-association`,
        RvmContactInfo: (rvmId: string) => `${INTERNAL_API_GET}${CUSTOMER_PAYMENTS}/rvms/${rvmId}`,
        WidgetPdf: (invoiceNr: string) =>
            `${INTERNAL_API_GET}${CUSTOMER_PAYMENTS}/pdf/${invoiceNr}`,
        PaymentsPdf: () => `${CUSTOMER_PAYMENTS}/pdf`,
        PaymentsExcel: (
            fromDate: string,
            toDate: string,
            rvms: string,
            onlyPaid: boolean,
            invoiceNr: string,
            reference: string
        ) =>
            `${INTERNAL_API_GET}${CUSTOMER_PAYMENTS}/excel?fromDate=${fromDate}&toDate=${toDate}&onlyPaid=${onlyPaid}&rvms=${rvms}&invoiceNr=${invoiceNr}&reference=${reference}`,
        PaymentsSearchPdf: (
            fromDate: string,
            toDate: string,
            rvms: string,
            onlyPaid: boolean,
            invoiceNr: string,
            reference: string
        ) =>
            `${INTERNAL_API_GET}${CUSTOMER_PAYMENTS}/searchpdf?fromDate=${fromDate}&toDate=${toDate}&onlyPaid=${onlyPaid}&rvms=${rvms}&invoiceNr=${invoiceNr}&reference=${reference}`,
    },
    Forms: {
        SiteNomination: () => `${INTERNAL_API_POST}${FORMS}/sitenomination`,
    },
    CompanyInformations: {
        AllAssociations: () => `${INTERNAL_API_GET}${COMPANY_INFORMATIONS}/all-associations`,
        SupplierTypes: () => `${INTERNAL_API_GET}${CUSTOMER_BASE_DATA}/supplier-types`,
        SupplierTypesPost: () => `${INTERNAL_API_POST}${COMPANY_INFORMATIONS}/supplier-types`,
        CompanyInfo: () => `${INTERNAL_API_GET}${COMPANY_INFORMATIONS}/company-info`,
        Addresses: () => `${INTERNAL_API_GET}${COMPANY_INFORMATIONS}/addresses`,
        ContactPersons: () => `${INTERNAL_API_GET}${COMPANY_INFORMATIONS}/contact-persons`,
        Agreements: () => `${INTERNAL_API_GET}${COMPANY_INFORMATIONS}/agreements`,
        DeleteContactPerson: (id: string) => `${INTERNAL_API_DELETE}${COMPANY_INFORMATIONS}/contact-person/${id}`,
        Address: () => `${INTERNAL_API_POST}${COMPANY_INFORMATIONS}/address`,
        Users: () => `${INTERNAL_API_GET}${COMPANY_INFORMATIONS}/users`,
        ContactTypes: () => `${INTERNAL_API_GET}${CUSTOMER_BASE_DATA}/contact-person-types`,
        AddContactPerson: () => `${INTERNAL_API_POST}${COMPANY_INFORMATIONS}/contact-person`,
        EditContactPerson: (id: string) =>  `${INTERNAL_API_POST}${COMPANY_INFORMATIONS}/contact-person/${id}/edit`,
        AgreementPdf: (id: string, appendixIndex: number | string) => `${INTERNAL_API_GET}${COMPANY_INFORMATIONS}/agreement/${id}/pdf?appendix=${appendixIndex}`,
        AllCustomers: () => `${INTERNAL_API_GET}${COMPANY_INFORMATIONS}/all-customers`,
    },
    RecyclingStations: {
        Stations: (filter?: boolean) =>
            `${INTERNAL_API_GET}${CUSTOMER_BASE_DATA}/${RECYCLING_SITES}${filter ? '?filter=true' : ''}`,
        StationDetails: (stationId: string) =>
            `${INTERNAL_API_GET}${CUSTOMER_BASE_DATA}/${RECYCLING_SITES}/${stationId}`,
    },
    Search: {
        SearchResults: () => `${SEARCH}`,
    },
    StoreFlyers: {
        Flyers: () => `${INTERNAL_API_GET}${STORE_FLYERS}`,
        DownloadFlyer: () => `${INTERNAL_API_POST}${STORE_FLYERS}/download`,
        OrderFlyer: () => `${INTERNAL_API_POST}${STORE_FLYERS}/order`,
    },
    SupplierArticles: {
        Articles: (filterInactive: boolean) =>
            `${INTERNAL_API_GET}${SUPPLIER_ARTICLES}?filterInactive=${filterInactive}`,
        ArticlePdf: (articleId: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_ARTICLES}/${articleId}/pdf`,
        ArticleExcel: (articleId: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_ARTICLES}/${articleId}/excel`,
        ArticlesExcel: () => `${INTERNAL_API_POST}${SUPPLIER_ARTICLES}/excel`,
        Unregister: (salesStopDate: string) =>
            `${INTERNAL_API_POST}${SUPPLIER_ARTICLES}/unregister/${salesStopDate}`,
    },
    SupplierArticleRegistration: {
        ArticleRegistrations: () => `${INTERNAL_API_GET}${SUPPLIER_ARTICLE_REGISTRATION}`,
        ArticleRegistration: (errandId: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_ARTICLE_REGISTRATION}/${errandId}`,
        ArticleRegistrationDelete: (errandId: string) =>
            `${INTERNAL_API_DELETE}${SUPPLIER_ARTICLE_REGISTRATION}/${errandId}`,
        ProductTypesAndMaterials: () =>
            `${INTERNAL_API_GET}${SUPPLIER_ARTICLE_REGISTRATION}/producttypes-and-materials`,
        ColorsAndVolumes: (productTypeId: string, materialId: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_ARTICLE_REGISTRATION}/${productTypeId}/${materialId}/colors-and-volumes`,
        DepositCode: (
            productTypeId: string,
            materialId: string,
            volumeId: string,
            customVolume: string
        ) =>
            `${INTERNAL_API_GET}${SUPPLIER_ARTICLE_REGISTRATION}/deposit-code?productTypeId=${productTypeId}&materialId=${materialId}&volumeId=${volumeId}&customVolume=${customVolume}`,
        ValidateEan: (ean: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_ARTICLE_REGISTRATION}/ean/${ean}`,
        ValidateEanWithErrand: (ean: string, errandId: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_ARTICLE_REGISTRATION}/ean/${ean}/${errandId}`,
        SubmitArticleRegistration: (errandId: string) =>
            `${INTERNAL_API_POST}${SUPPLIER_ARTICLE_REGISTRATION}/${errandId}/submit`,
    },
    SupplierBaseData: {
        Countries: () => `${INTERNAL_API_GET}${CUSTOMER_BASE_DATA}/countries`,
    },
    SupplierCompanyRegistrations: {
        SupplierCompanyInfo: (orgnr: string, type: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_COMPANY_REGISTRATION}/company/${orgnr}?type=${type}`,
        SubmitSupplierRegistration: () => `${INTERNAL_API_POST}${SUPPLIER_COMPANY_REGISTRATION}`,
        ValidateVatNr: (vatNr: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_COMPANY_REGISTRATION}/validatevatnnr/${vatNr}`,
    },
    SupplierDepositLabel: {
        DepositLabels: () => `${INTERNAL_API_GET}${SUPPLIER_DEPOSIT_LABELS}`,
        SubmitOrder: () => `${INTERNAL_API_POST}${SUPPLIER_DEPOSIT_LABELS}/order`,
        NutritionDeclaration: (articleId: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_DEPOSIT_LABELS}/${articleId}/nutrition`,
        Price: (lookUpId: string, quantity: number) =>
            `${INTERNAL_API_GET}${SUPPLIER_DEPOSIT_LABELS}/${lookUpId}/price?quantity=${quantity}`,
        Articles: () => `${INTERNAL_API_GET}${SUPPLIER_DEPOSIT_LABELS}/articles`,
        ShippingCost: (
            quantity: number,
            orderAmount: number,
            shippingRateGroup: string
        ) =>
            `${INTERNAL_API_GET}${SUPPLIER_DEPOSIT_LABELS}/shippingprice?quantity=${quantity}&orderAmount=${orderAmount}&shippingRateGroup=${shippingRateGroup}`,
        DepositLabelOrderHistory: () => `${INTERNAL_API_GET}${SUPPLIER_DEPOSIT_LABELS}/order-history`,
        DepositLabelOrderDetails: (orderId: string) => `${INTERNAL_API_GET}${SUPPLIER_DEPOSIT_LABELS}/order-history/${orderId}`,
    },
    SupplierInvoice: {
        Invoices: () => `${INTERNAL_API_GET}${SUPPLIER_INVOICES}`,
        Invoice: (id: string) => `${INTERNAL_API_GET}${SUPPLIER_INVOICES}/${id}`,
    },
    SupplierSalesReporting: {
        Periods: (fromDate: string, toDate: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_SALESREPORTING}?fromDate=${fromDate}&toDate=${toDate}`,
        UnReportedPeriods: () => `${INTERNAL_API_GET}${SUPPLIER_SALESREPORTING}/unreported`,
        Period: (periodId: string) => `${INTERNAL_API_POST}${SUPPLIER_SALESREPORTING}/${periodId}`,
        PeriodArticles: (periodId: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_SALESREPORTING}/${periodId}/articles`,
        PeriodExcelRegistration: (periodId: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_SALESREPORTING}/${periodId}/excelregistration`,
        PeriodExcelRegistrationPost: (periodId: string) =>
            `${INTERNAL_API_POST}${SUPPLIER_SALESREPORTING}/${periodId}/excelregistration`,
        PeriodExcelReport: (compositeReportId: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_SALESREPORTING}/${compositeReportId}/excelreport`,
        ReportDetails: (periodId: string, reportId: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_SALESREPORTING}/${periodId}/report/${reportId}`,
    },
    SupplierSalesStatements: {
        ExcelReport: (salesStatementId: string, salesYear: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_SALES_STATEMENTS}/${salesStatementId}/salesstatementsexcelreport/${salesYear}`,
        SalesStatements: () => `${INTERNAL_API_GET}${SUPPLIER_SALES_STATEMENTS}`,
        SalesStatementDetails: (salesStatementId: string) =>
            `${INTERNAL_API_GET}${SUPPLIER_SALES_STATEMENTS}/${salesStatementId}/details`,
        ApproveSalesStatement: (salesStatementId: string) =>
            `${INTERNAL_API_POST}${SUPPLIER_SALES_STATEMENTS}/${salesStatementId}/approve`,
    },
    SupplierStats: {
        Counties: () => `${INTERNAL_API_GET}${SUPPLIER_STATS}/counties`,
    },
    CustomerCompanyRegistrations: {
        Municipalities: () =>
          `${INTERNAL_API_GET}${ALL_CUSTOMERS}/customer/registrations/municipalities`,
        CustomerCompanyInfo: (orgNr: string) =>
          `${INTERNAL_API_GET}${ALL_CUSTOMERS}/customer/registrations/company/${orgNr}`,
        SubmitStoreRegistration: () =>
          `${INTERNAL_API_POST}${ALL_CUSTOMERS}/customer/registrations/store`,
        SubmitRestaurantRegistration: () =>
          `${INTERNAL_API_POST}${ALL_CUSTOMERS}/customer/registrations/restaurant`,
        AssociationHeards: () =>
          `${INTERNAL_API_GET}${ALL_CUSTOMERS}/customer/registrations/association/heards`,
        SubmitAssociationRegistration: () =>
          `${INTERNAL_API_POST}${ALL_CUSTOMERS}/customer/registrations/association`,
        CampsiteHeards: () =>
          `${INTERNAL_API_GET}${ALL_CUSTOMERS}/customer/registrations/campsite/heards`,
        SubmitCampsiteRegistration: () =>
          `${INTERNAL_API_POST}${ALL_CUSTOMERS}/customer/registrations/campsite`,
        SkiResortHeards: () =>
          `${INTERNAL_API_GET}${ALL_CUSTOMERS}/customer/registrations/skiresort/heards`,
        SubmitSkiResortRegistration: () =>
          `${INTERNAL_API_POST}${ALL_CUSTOMERS}/customer/registrations/skiresort`,
        WorkPlaceHeards: () =>
          `${INTERNAL_API_GET}${ALL_CUSTOMERS}/customer/registrations/workplace/heards`,
        SubmitWorkPlaceRegistration: () =>
          `${INTERNAL_API_POST}${ALL_CUSTOMERS}/customer/registrations/workplace`,
    },
    UserManagement: {
        Users: () => `${INTERNAL_API_GET}${USER_MANAGEMENT}/users`,
        AccessLevels: (userId?: string) =>
            `${INTERNAL_API_GET}${USER_MANAGEMENT}/access-levels/${userId}`,
        User: () => `${INTERNAL_API_POST}${USER_MANAGEMENT}/user`,
        SendActivationEmail: () => `${INTERNAL_API_POST}${USER_MANAGEMENT}/send-activation-email`,
        DeleteUser: (userId: string) => `${INTERNAL_API_DELETE}${USER_MANAGEMENT}/user/${userId}`,
        EditUser: (userId: string) => `${INTERNAL_API_POST}${USER_MANAGEMENT}/user/${userId}`,
        Activation: (code: string) => `${INTERNAL_API_GET}${USER_MANAGEMENT}/activation/${code}`,
        ActivationPost: (code: string) => `${INTERNAL_API_POST}${USER_MANAGEMENT}/activation/${code}`,
        ActivationStatus: (activityId: string) =>
            `${INTERNAL_API_GET}${USER_MANAGEMENT}/activation-status/${activityId}`,
    },
    UserProfile: {
        Profile: () => `${INTERNAL_API_GET}${PROFILE}/current-user`,
        Companies: () => `${INTERNAL_API_GET}${PROFILE}/companies`,
        AddCustomer: () => `${INTERNAL_API_POST}${PROFILE}/company`,
        ContactCustomer: () => `${INTERNAL_API_POST}${PROFILE}/contact`,
    },
}