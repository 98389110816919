import { EnvObject } from './types'

export const environments: EnvObject[] = [
    {
        env: 'development',
        host: ['www.rp-pm-dev.local'],
        urls: {
            site: 'https://www.rp-pm-dev.local:8000',
            api: `https://www.rp-pm-dev.local:8000/api`,
            login: 'https://login.www.rp-pm-dev.local:5001',
        },
    },
    {
        env: 'integration',
        host: ['integration.pantamera.nu'],
        urls: {
            site: 'https://integration.pantamera.nu',
            api: `https://integration.pantamera.nu/api`,
            login: 'https://login.www.rp-pm-test.pantamera.nu',
        },
    },
    {
        env: 'preproduction',
        host: ['preproduction.pantamera.nu'],
        urls: {
            site: 'https://preproduction.pantamera.nu',
            api: `https://preproduction.pantamera.nu/api`,
            login: 'https://login.www.rp-pm-test.pantamera.nu',
        },
    },
    {
        env: 'production',
        host: ['pantamera.nu'],
        urls: {
            site: 'https://pantamera.nu',
            api: `https://pantamera.nu/api`,
            login: 'https://login.www.rp-pm-prod.pantamera.nu',
        },
    },
]
